import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Grid, Container, Avatar, CircularProgress } from '@mui/material';
import { Google } from '@mui/icons-material';
import { checkGoogleAuth, disconnectGoogleAuth, connectWithGoogle } from '../services/google-tool.service';
import { useApp, useAuth } from '../hooks';

const ToolsPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { state, dispatch }: any = useApp();
  const { auth }: any = useAuth();

  useEffect(() => {
    const fetchAuthStatus = async () => {
      const authStatus = await checkGoogleAuth(state.selectedOrganisation?._id);
      setIsAuthenticated(authStatus);
      setLoading(false);
    };

    fetchAuthStatus();
  }, []);

  const handleDisconnect = async () => {
    setLoading(true);
    const disconnectStatus = await disconnectGoogleAuth(state.selectedOrganisation?._id);
    if (disconnectStatus) {
      setIsAuthenticated(false);
    }
    setLoading(false);
  };

  const handleConnect = async () => {
    setLoading(true);
    await connectWithGoogle(auth.user.uid, state.selectedOrganisation?._id);
    setLoading(false);
  };

  return (
    <div className="w-96">
      <Typography variant="h4" gutterBottom>
        Connect Your Tools
      </Typography>
      <hr className='w-96 pb-4'/>
      <Grid container spacing={4}>
        <Grid item>
          <Card elevation={3}>
            <CardMedia
              component="div"
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pt: 4 }}
            >
              <Avatar sx={{ bgcolor: '#4285F4', width: 80, height: 80 }}>
                <Google sx={{ fontSize: 40, color: '#fff' }} />
              </Avatar>
            </CardMedia>
            <CardContent>
              <Typography variant="h6" component="div" gutterBottom>
                Google Workspace
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Connect your Google Workspace account to enable DatalinkerAI to create tools for accessing services like sending emails, booking appointments, finding docs, updating sheets, and more directly from the chat section.
              </Typography>
              {loading ? (
                <CircularProgress sx={{ mt: 3 }} />
              ) : isAuthenticated ? (
                <>
                  <Typography variant="body1" color="text.primary" sx={{ mt: 3 }}>
                    Authenticated with Google Workspace
                  </Typography>
                  <Button variant="contained" color="secondary" fullWidth sx={{ mt: 3 }} onClick={handleDisconnect}>
                    Disconnect
                  </Button>
                </>
              ) : (
                <Button variant="contained" color="primary" fullWidth sx={{ mt: 3 }} onClick={handleConnect}>
                  Connect
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default ToolsPage;
